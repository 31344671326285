import React, { Component } from "react"
import FixedMenu from "../components/FixedMenu" 
import ImageCard from "../components/ImageCard"
import ItemCard from "../components/ItemCard"
import SEO from "../components/seo"

import SiteContext from "../context/SiteContext"

class ProductsPage extends Component {

    static contextType = SiteContext;

    constructor(props) {
        super(props)
        this.state = {
            products: [],
            relatedProducts: [],
            categories: [],
            title:'',
            subtitle:'',
            slug:'',
            id:'',
            imagePath:'',
            children:[],
            results:[]
        }
    }

    componentDidMount() {

        let site = 'finestone'
        
        this.context.changeSite(site)
        this.setState({ site })
        
        const { productData, productCategories, product } = this.props.pageContext

        let products = []
        let categories = []
    
        if (typeof productData !== 'undefined') {
            products = productData.data.craft.entries
        }
        if (typeof productCategories !== 'undefined') {
            categories = productCategories.data.craft.categories
        }

        const {
            title,
            slug,
            id,
            summary,
            imagePath,
            children
        } = this.props.pageContext.product


        const sortedCategories = categories.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));


        let results = [];
        let numberOfRelatedProducts = 0;
        let subtitle = "";

        if (children && children.length){
            children.map(child => {
                categories.map( productCat => {
                    if (child.id === productCat.id){
                      
                        //add number of products related to this child product family
                        numberOfRelatedProducts = 0;
                        products.map( product => {
                            product.productCategory.map ( fam => {
                                if (child.id === fam.id ){
                                    numberOfRelatedProducts++;
                                }
                            })
                        })
                        if (numberOfRelatedProducts === 1) {
                            productCat['subtitle'] = numberOfRelatedProducts + " Product";
                        } 
                        else if (numberOfRelatedProducts > 1) {
                            productCat['subtitle'] = numberOfRelatedProducts + " Products";
                        } 
                        else {
                            productCat['subtitle'] = "";
                        }
                        results.push(productCat);
                    }
                })
            })
        } else {
            numberOfRelatedProducts = 0;
            products.map( product => {
                product.productCategory.map ( cat => {
                    if (id === cat.id ){
                        numberOfRelatedProducts++;
                    }
                })
            })
            if (numberOfRelatedProducts === 1) {
                subtitle = numberOfRelatedProducts + " Product";
            } 
            else if (numberOfRelatedProducts > 1) {
                subtitle = numberOfRelatedProducts + " Products";
            } 
        }

        this.setState({
            products,
            title,
            subtitle,
            slug,
            summary,
            imagePath,
            children,
            results,
            categories: sortedCategories
        })

    }

    

    render() {


        let relatedProducts = [];
        this.state.products.map(product => {
            return product.productCategory.map(cat => {
                // console.log('family', family)
                if (cat.slug === this.state.slug) {
                    relatedProducts.push(product)
                }
            })
        })


        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <>
                        <SEO title="Products" />
                        <div className="wrapper">
                            <div className="content-wrapper app-header-style-image">
                                <div className="app-header">
                                    <div className="app-header-bg" style={{backgroundImage: `url(${this.state.imagePath}?width=940)` }}></div>
                                    <div className="app-header-content">
                                        <h1 className="app-header__title title--left">{this.state.title}</h1>
                                        <div className="app-header__subtitle">{this.state.subtitle}</div>
                                    </div>  
                                </div>
                                <div>

                                { this.state.results.length > 0 
                                ?
                                    <div className="image-card__wrapper">
                                        {this.state.results.map( result => {
                                            return (
                                            <ImageCard 
                                                imagePath={`url(${result.imagePath}?width=940)`} 
                                                url={`/products/${result.slug}`}
                                                title={result.title}
                                                subtitle={result.subtitle}
                                            />)
                                        })}
                                    </div>
                                :
                                    <div className="item-card__wrapper">
                                        {relatedProducts.map(product => {
                                            return (
                                            <ItemCard 
                                                    imagePath={`url(${product.imagePath}?width=400)`}
                                                    url={`/product/${product.slug}?fromUrl=${this.state.slug}&fromTitle=${this.state.title}`}
                                                    title={product.title}
                                                    viewType="Product"
                                            />
                                            )
                                        })}
                                    </div>   
                                }
                                </div>                       
                            </div>
                            <FixedMenu active="products" site={siteContext.site} /> 
                        </div>
                    </>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default ProductsPage